<script>
// import {vSlidenIn} from "@/utils/vSlidenIn";
export default {
  directives:{
    // slidenIn:vSlidenIn
  },
  props:{
    imagepath:{
      type:String,
      default:require('@/assets/u19.png')
    },
    title2:{
      type:String,
      default:"Comprehensive system integration and global operational support to create an efficient, secure, and highly collaborative smart oilfield ecosystem"
    },
    title1:{
      type:String,
      default:"Improved Operational Efficiency"
    },
    isicon:{
      type:Number,
      default:1
    },
    padw:{
      default:'0'
    }
  },
  created() {
    console.log("isicon",this.isicon)
  },
  computed:{
    dynamicClass(){
      let sult="";
      switch (this.isicon) {
        case 0:
          sult='card2';
          break;
        case 1:
          sult='card';
          break;
        case 2:
          sult='card3';
          break;
        default:
          sult='card';
      }
      return sult;
    },
  }

}
</script>

<template>
  <div  :class="dynamicClass" :style="padw==0?{}:{'padding-left':padw+'vw','padding-right':padw+'vw'}">
    <div class="back">
      <div class="imgDesign">
        <img loading="lazy"  style="border-radius: 9px" :src="imagepath">
      </div>
      <div class="titleDesign">
        <div class="title1" v-html="title1"></div>
        <div class="title2" v-html="title2">
        </div>
      </div>
    </div>


  </div>
</template>

<style scoped lang="scss">
.card{
  //cursor: pointer;
  width: 100%;
  height:100% ;
  padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
  opacity: 1;

  .back{
    background: white;
    border-radius: 9px;
    position: relative;
    box-shadow: 2px 0 12px 0px #c9d9f5;
    height:100% ;
    overflow:hidden;
    .imgDesign{
      width: fit-content;
      height:8vh;
      margin: 3vh auto 0 auto;
      opacity: 1;
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
    }
    .titleDesign{
      padding: 0 1.5vw 2.5248454883vh 1.5vw;
      margin:0 auto;
      color: black;
      width:100%;
      float: right;
      .title1{
        margin: 3vh auto ;
        font-size: 1.1709416522vw;
        font-weight: bold;
        text-align: center;
      }
      .title2{
        line-height: 2.8vh;
        font-size: 0.9vw;
      }
    }
  }
}
.card2{
  //cursor: pointer;
  width: 100%;
  height:100% ;
  padding:0.98887515451174vh 3.69324090121317vw;
  opacity: 1;

  .back{
    background: white;
    border-radius: 9px;
    position: relative;
    box-shadow: 2px 0 12px 0px #c9d9f5;
    height:100% ;
    overflow:hidden;
    .imgDesign{
      width: 100%;
      height:27vh;
      opacity: 1;
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
    }
    .titleDesign{
      padding: 0 1.5vw 2.5248454883vh 1.5vw;
      margin:0 auto;
      color: black;
      width:100%;
      float: right;
      .title1{
        margin:2vh 0;
        font-size: 1.1709416522vw;
        font-weight: bold;
        text-align: center;
      }
      .title2{
        line-height: 2.8vh;
        font-size: 0.9vw;
      }
    }
  }
}
.card3{
  //cursor: pointer;
  width: 100%;
  height:100% ;
  padding:0.98887515451174vh 3.69324090121317vw;
  opacity: 1;

  .back{
    background: white;
    //border-radius: 9px;
    position: relative;
    //box-shadow: 2px 0 12px 0px #c9d9f5;
    height:100% ;
    overflow:hidden;
    border-radius: 50px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    background: linear-gradient(to right, white, #bad5ed, white);
    .imgDesign{
      width: 100%;
      height:0vh;
      object-fit: cover;
      opacity: 1;
      //margin:3vh auto;
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
    }
    .titleDesign{
      padding: 2.5248454883vh 1.5vw 2.5248454883vh 1.5vw;
      margin:0 auto;
      color: black;
      width:100%;
      float: right;
      .title1{
        margin:2vh 0;
        font-size: 1.1709416522vw;
        font-weight: bold;
        text-align: center;
      }
      .title2{
        line-height: 3.1vh;
        font-size: 0.9vw;
      }
    }
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import maping from '@/meta'
// import {fetchData} from "pdfjs-dist";
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        meta:maping.Home,
        component: Home,
    },
    {
        path: '/privacy',
        name: 'Privacy',
        meta: {
            nameEn: '外部链接',
        },
        props: true, // 允许传递路由参数
        component: () => import( /* webpackChunkName: "about" */ '@/views/privacy.vue')

    },
    {
        path: '/ExternalLinkHandler ',
        name: 'ExternalLinkHandler',
        meta: {
            nameEn: '外部链接',
        },
        props: true, // 允许传递路由参数
        component: () => import( /* webpackChunkName: "about" */ '@/components/ExternalLinkHandler.vue')

    }, {
        path: '/feedbackEn',
        name: 'feedback',
        meta: {
            nameEn: 'feedback',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/feedback.vue')
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            nameEn: '登录',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/login.vue')
    },
    {
        path: '/makeHole',
        name: 'makeHole',
        meta: {
            nameEn: '智能钻井',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/makeHole.vue')
    },
    {
        path: '/info',
        name: 'info',
        meta: {
            nameEn: '个人信息',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/info.vue'),
        children: [{
            path: '/userInformation',
            name: 'userInformation',
            component: () => import('@/views/userLog/userInformation.vue'), // 个人信息
            meta: {
                requireAuth: true,
                needRefresh: true, // 需要刷新
                nameEn: ' 个人信息',
            },
        },
            {
            path: '/secure',
            name: 'secure',
            component: () => import('@/views/userLog/secure.vue'), // 个人信息
            meta: {
                requireAuth: true,
                needRefresh: true, // 需要刷新
                nameEn: ' 账户安全',
            },
        },
            {
                path: '/changePhone',
                name: 'ChangePhone',
                component: () => import('@/views/userLog/ChangePhone.vue'), // 修改手机号
                meta: {
                    requireAuth: true,
                    nameEn:'修改手机号',
                },
            },
            {
                path: '/changePassword',
                name: 'ChangePassword',
                component: () => import('@/views/userLog/ChangePassword.vue'), // 修改密码
                meta: {
                    requireAuth: true,
                    nameEn:'修改密码',
                },
            },
            {
                path: '/changeEmail',
                name: 'ChangeEmail',
                component: () => import('@/views/userLog/ChangeEmail.vue'), // 修改密码
                meta: {
                    requireAuth: true,
                    nameEn:'修改密码',
                },
            },]
    },
    {
        path: '/infoMobile',
        name: 'infoMobile',
        meta: {
            nameEn: '个人信息',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/infoMobile.vue'),
        children: [
        ]
    },
    {
        path: '/userInfoMobile',
        name: 'userInfoMobile',
        component: () => import('@/views/userLog/userInfoMobile.vue'), // 个人信息
        meta: {
            requireAuth: true,
            needRefresh: true, // 需要刷新
            nameEn: ' 个人信息',
        },
    },
    {
        path: '/secureMobile',
        name: 'secureMobile',
        component: () => import('@/views/userLog/secureMobile.vue'), // 个人信息
        meta: {
            requireAuth: true,
            needRefresh: true, // 需要刷新
            nameEn: ' 账户安全',
        },
    },
    {
        path: '/changePhone1',
        name: 'ChangePhone1',
        component: () => import('@/views/userLog/ChangePhone1.vue'), // 修改手机号
        meta: {
            requireAuth: true,
            nameEn:'修改手机号',
        },
    },
    {
        path: '/changePassword1',
        name: 'ChangePassword1',
        component: () => import('@/views/userLog/ChangePassword1.vue'), // 修改密码
        meta: {
            requireAuth: true,
            nameEn:'修改密码',
        },
    },
    {
        path: '/changeEmail1',
        name: 'ChangeEmail1',
        component: () => import('@/views/userLog/ChangeEmail1.vue'), // 修改密码
        meta: {
            requireAuth: true,
            nameEn:'修改密码',
        },
    },
    {
        path: '/frackOil',
        name: 'frackOil',
        meta: {
            nameEn: '智能压裂',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/frackOil.vue')
    },
    {
        path: '/getOil',
        name: 'getOil',
        meta: {
            nameEn: '智能采油气',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/getOil.vue')
    },
    {
        path: '/menageOil',
        name: 'menageOil',
        meta: {
            nameEn: '油田管理',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/menageOil.vue')
    },
    {
        path: '/monitorOil',
        name: 'monitorOil',
        meta: {
            nameEn: '地层监测',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/monitorOil.vue')
    },  {
        path: '/monitorEqu',
        name: 'monitorEqu',
        meta: {
            nameEn: '设备设施监测',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/monitorEqu.vue')
    }, {
        path: '/groundOil',
        name: 'groundOil',
        meta: {
            nameEn: '油藏地质',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/groundOil.vue')
    },
    {
        path: '/gptOil',
        name: 'gptOil',
        meta: {
            nameEn: '油藏地质',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/gptsSubordinate/gptOil.vue')
    },
    // {
    //     path: '/text',
    //     name: 'text',
    //     meta: {
    //         nameEn: 'ces ',
    //         keepAlive: true,
    //     },
    //     component: () => import( /* webpackChunkName: "about" */ '@/components/text.vue')
    // },
    {
        path: '/gptKnowledge',
        name: 'gptKnowledge',
        meta: {
            nameEn: '企业内部管理类知识GPT开发服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/gptsSubordinate/knowledge.vue')
    },
    {
        path: '/gptData',
        name: 'gptData',
        meta: {
            nameEn: '企业管理数智化整体解决方案服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/gptsSubordinate/data.vue')
    },
    {
        path: '/wisdom',
        name: 'wisdom',
        meta: {
            nameEn: '数据驱动智慧决策平台设计及建设服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/gptsSubordinate/wisdom.vue')
    },
    {
        path: '/design',
        name: 'design',
        meta: {
            nameEn: '智慧员工社区设计及建设服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/gptsSubordinate/design.vue')
    },
    {
        path: '/subordinate',
        name: 'subordinate',
        meta: {
            nameEn: 'header',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/components/subordianteheader.vue')
    },
    {
        path: '/gptAssistants',
        name: 'subordinate',
        meta: {
            nameEn: 'header',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/component/GPTAssistants.vue')
    },
    {
        path: '/gptAssistantsMobile',
        name: 'subordinate',
        meta: {
            nameEn: 'header',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/component/GPTAssistantsMobile.vue')
    },
    {
        path: '/plat',
        name: 'plat',
        meta: {
            nameEn: '智慧电商平台设计、建设及运营服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/gptsSubordinate/plat.vue')
    },
    {
        path: '/Visualization',
        name: 'Visualization',
        meta: {
            nameEn: '远程传输',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/software/third/Visualization.vue')
    },
    {
        path: '/smartstation',
        name: '/smartstation',
        meta: {
            nameEn: '远程传输',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/software/third//smartstation.vue')
    },
    {
        path: '/smartProduct',
        name: 'smartProduct',
        meta: {
            nameEn: '远程传输',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/software/third/smartProduct.vue')
    },
    {
        path: '/smartControl',
        name: 'smartControl',
        meta: {
            nameEn: '远程传输',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/software/third/smartControl.vue')
    },
    {
        path: '/Inspection',
        name: 'Inspection',
        meta: {
            nameEn: '远程传输',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/software/third/Inspection.vue')
    },
    {
        path: '/test',
        name: 'test',
        meta: {
            nameEn: '智慧电商平台设计、建设及运营服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/test.vue')
    },
    {
        path: '/automation',
        name: 'automation',
        meta: {
            nameEn: '管理流程自动化平台设计及建设服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/gptsSubordinate/automation.vue')
    },



    {
        path: '/gptDesign',
        name: 'gptDesign',
        meta: {
            nameEn: '设计GPTs',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/gptsSubordinate/gptDesign.vue')
    },{
        path: '/gptTranslate',
        name: 'gptTranslate',
        meta: {
            nameEn: '解释GPTs',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/gptsSubordinate/gptTranslate.vue')
    },{
        path: '/gptTechnology',
        name: 'gptTechnology',
        meta: {
            nameEn: '工艺GPTs',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/gptsSubordinate/gptTechnology.vue')
    },
    {
        path: '/gptHole',
        name: 'gptHole',
        meta: {
            nameEn: '智能钻井',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/gptsSubordinate/gptHole.vue')
    },
    {
        path: '/gptFrack',
        name: 'gptFrack',
        meta: {
            nameEn: '智能压裂',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/gptsSubordinate/gptFrack.vue')
    },
    {
        path: '/gptGet',
        name: 'gptGet',
        meta: {
            nameEn: '智能采油气',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/gptsSubordinate/gptGet.vue')
    },
    {
        path: '/gptField',
        name: 'gptField',
        meta: {
            nameEn: '智能采油气',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/gptsSubordinate/gptField.vue')
    },
    {
        path: '/gptEqu',
        name: 'gptEqu',
        meta: {
            nameEn: '设备设施监测',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/gptsSubordinate/gptEqu.vue')
    },
    {
        path: '/gptGround',
        name: 'gptGround',
        meta: {
            nameEn: '地层监测',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/gptsSubordinate/gptGround.vue')
    },
    {
        path: '/recognition',
        name: 'recognition',
        meta: {
            nameEn: '储层识别/岩性识别',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/recognition.vue')
    },
    {
        path: '/recognition1',
        name: 'recognition1',
        meta: {
            nameEn: '储层识别/岩性识别',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/recognition1.vue')
    },
    {
        path: '/fiber',
        name: 'fiber',
        meta: {
            nameEn: '光纤井筒',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/fiber.vue')
    },
    {
        path: '/ground',
        name: 'ground',
        meta: {
            nameEn: '智能地质导向',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/ground.vue')
    },
    {
        path: '/ground1',
        name: 'ground1',
        meta: {
            nameEn: '智能地质导向',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/ground1.vue')
    },
    {
        path: '/frack',
        name: 'frack',
        meta: {
            nameEn: '压裂施工参数设计与优化',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/frack.vue')
    },
    {
        path: '/drilling',
        name: 'drilling',
        meta: {
            nameEn: '钻井参数优化',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/drilling.vue')
    },

    {
        path: '/assisted',
        name: 'assisted',
        meta: {
            nameEn: '钻井参数优化',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/assisted.vue')
    },
    {
        path: '/decision1',
        name: 'decision1',
        meta: {
            nameEn: '智能注采决策',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/decision1.vue')
    },
    {
        path: '/videoPlay',
        name: 'videoPlay',
        meta: {
            nameEn: '智能注采决策',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/components/videoPlay.vue')
    },
    {
        path: '/videoPlayMobile',
        name: 'videoPlayMobile',
        meta: {
            nameEn: '智能注采决策',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/components/videoPlayMobile.vue')
    },
    {
        path: '/alarm',
        name: 'alarm',
        meta: {
            nameEn: '地质层工况报警',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/alarm.vue')
    },
    {
        path: '/decision',
        name: 'decision',
        meta: {
            nameEn: '智能注采决策',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/decision.vue')
    },    {
        path: '/Stratum',
        name: 'Stratum',
        meta: {
            nameEn: '地质导向',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/Stratum.vue')
    },    {
        path: '/plug',
        name: 'plug',
        meta: {
            nameEn: '沙堵',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/plug.vue')
    }, {
        path: '/training',
        name: 'training',
        meta: {
            nameEn: '智能培训',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/Anxun/training.vue')
    },{
        path: '/build',
        name: 'build',
        meta: {
            nameEn: '智能油气生产优化',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/Anxun/build.vue')
    },
    {
        path: '/monitor',
        name: 'monitor',
        meta: {
            nameEn: '油气田视频监控',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/monitior.vue')
    },
    {
        path: '/monitior1',
        name: 'monitior1',
        meta: {
            nameEn: '油气田视频监控',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/monitior1.vue')
    },
    {
        path: '/equipment',
        name: 'equipment',
        meta: {
            nameEn: '设备智能监测',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/equipment.vue')
    },
    {
        path: '/equipment1',
        name: 'equipment1',
        meta: {
            nameEn: '设备智能监测',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/equipment1.vue')
    },
    {
        path: '/light',
        name: 'light',
        meta: {
            nameEn: '光纤找堵漏/找漏水智能监测预警',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/light.vue')
    },
    {
        path: '/light1',
        name: 'light1',
        meta: {
            nameEn: '光纤找堵漏/找漏水智能监测预警',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/light1.vue')
    },
    {
        path: '/station',
        name: 'station',
        meta: {
            nameEn: '智慧场站解决方案',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/Anxun/station.vue')
    },
    {
        path: '/Anxunheader',
        name: 'Anxunheader',
        meta: {
            nameEn: 'Anxunheader',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/Anxun/component/Anxunheader.vue')
    },
    {
        path: '/security',
        name: 'security',
        meta: {
            nameEn: '智慧安防解决方案',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/Anxun/security.vue')
    },{
        path: '/storage',
        name: 'storage',
        meta: {
            nameEn: '智慧油气存储',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/Anxun/storage.vue')
    },
    {
        path: '/product',
        name: 'product',
        meta: {
            nameEn: '油气生产智能管控平台',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/Anxun/product.vue')
    },
    {
        path: '/subHeadermobile',
        name: 'subHeadermobile',
        meta: {
            nameEn: 'subHeadermobile',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/components/subHeaderMobile.vue')
    },
    {
        path: '/equipmentAnxun',
        name: 'equipmentAnxun',
        meta: {
            nameEn:'设备设施智能监测',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/Anxun/equipmentAnxun.vue')
    },
    {
        path: '/technology',
        name: 'technology',
        meta: {
            nameEn: '场站工艺管理平台',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/Anxun/technology.vue')
    },
    {
        path: '/imageUpTextDown',
        name: 'imageUpTextDown',
        meta: {
            nameEn: '场站工艺管理平台',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/component/imageUpTextDown.vue')
    },
    {
        path: '/monitorAnxun',
        name: 'monitorAnxun',
        meta: {
            nameEn: '石油工程远程智慧监控指挥平台',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/Anxun/monitorAnxun.vue')
    },
    {
        path: '/pipeline',
        name: 'pipeline',
        meta: {
            nameEn: '智慧管网解决方案',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/Anxun/network.vue')
    },
    {
        path: '/basic',
        name: 'basic',
        meta: {
            nameEn: '智慧油气基础设施建设',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/Anxun/basic.vue')
    },
    {
        path: '/digital',
        name: 'digital',
        meta: {
            nameEn: '油气数智化全场景伴随式运维服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/Anxun/digital.vue')
    },
    {
        path: '/traning',
        name: 'traning',
        meta: {
            nameEn: '人工智能全员普及培训及转型咨询服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/services/traning.vue')
    },
    {
        path: '/company',
        name: 'company',
        meta: {
            nameEn: '油气企业自建GPT云平台服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/services/company.vue')
    },
    {
        path: '/company2',
        name: 'company2',
        meta: {
            nameEn: '油气企业自建GPT云平台服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/services/company2.vue')
    }, {
        path: '/computingPower',
        name: 'computingPower',
        meta: {
            nameEn: '油气企业自建GPT云平台服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/services/computingPower.vue')
    },
    {
        path: '/consult',
        name: 'consult',
        meta: {
            nameEn: '企业人工智能转型咨询',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/services/consult.vue')
    },
    {
        path: '/business',
        name: 'business',
        meta: {
            nameEn: '油气企业自建GPT云平台服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/services/business.vue')
    },
    {
        path: '/service',
        name: 'service',
        meta: {
            nameEn: '安东油气通行业GPT云平台服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/services/service.vue')
    },
    {
        path: '/construct',
        name: 'construct',
        meta: {
            nameEn: '油气企业自建GPT云平台服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/services/construct.vue')
    },
    {
        path: '/menage',
        name: 'menage',
        meta: {
            nameEn: '企业管理GPT开发服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/services/menage.vue')
    },
    {
        path: '/application',
        name: 'application',
        meta: {
            nameEn: '数智油田GPT开发应用项目服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/services/application.vue')
    },
    {
        path: '/oilApplication',
        name: 'oilApplication',
        meta: {
            nameEn: '数智油田GPT开发应用项目服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/services/oilApplication.vue')
    },

    {
        path: '/knowledgethis',
        name: 'knowledgethis',
        meta: {
            nameEn: '企业内部管理类知识GPT开发服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/menages/knowledge.vue')
    },
    {
        path: '/data',
        name: 'data',
        meta: {
            nameEn: '企业管理数智化整体解决方案服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/menages/data.vue')
    },
    {
        path: '/debris',
        name: 'debris',
        meta: {
            nameEn: '数字岩屑岩石组成成分智能检测',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/third/debris.vue')
    },
    {
        path: '/softwareOilfield',
        name: 'softwareOilfield',
        meta: {
            nameEn: 'softwareOilfield',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/software/softwareOilfield')
    },
    {
        path: '/softwareCustomized',
        name: 'softwareCustomized',
        meta: {
            nameEn: 'softwareCustomized',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/software/softwareCustomized.vue')
    },
    {
        path: '/softwareCustomizedMobile',
        name: 'softwareCustomizedMobile',
        meta: {
            nameEn: 'softwareCustomizedMobile',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/software/softwareCustomizedMobile.vue')
    },
    {
        path: '/softwareOilfieldMobile',
        name: 'softwareOilfieldMobile',
        meta: {
            nameEn: 'softwareOilfieldMobile',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/software/softwareOilfieldMobile.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    console.log("tooto",from)
    console.log(to.path === '/ExternalLinkHandler')
    if (to.path === '/ExternalLinkHandler') {
        // 如果是从 ExternalLinkHandler 页面返回，恢复滚动位置
        router.back()
        next();
    } else  if(to.path === "/station"){
        next();
    } else {
        next();
    }

    // const data = await fetchData();
    // if (data) {
    //     next();
    // } else {
    //     next('/error'); // 如果数据加载失败，跳转到错误页面
    // }

});
export default router

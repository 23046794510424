<script>
import GPTAssistants from "@/views/component/GPTAssistants.vue";
export default {
  components:{
    GPTAssistants:GPTAssistants
  },
  data() {
    return {
      visible:false,
      active:true,
      textarea1: '',
      textarea2: '',
      messages: [{
        ok: 0,
        id: '00000',
        sender: "ai",
        introduce: "Hello, here you can get all-round AI application services for the oil and gas industry. <br>What can I do for you today?",
        resource: "",
        url:'nan',
        isSendingMessage: 0,
        language:''
      },
      ],
    }
  },
  methods:{
    startAutoScroll(){
      this.$parent.startAutoScroll()
    },
    toLinkedin(){
      window.open("https://linkedin.com/company/oil-gas-gpts")
    },
    toGPT(){
      window.open("/gptAssistants")
    },
    toNews(){
      window.open("https://www.oilgasinfoai.com/")
    },
    resizeTextarea() {
      const textarea = document.getElementById('text');
      if (textarea === null) {
        console.error('没有找到id为text的textarea元素');
        return;
      }
      const computedStyle = window.getComputedStyle(textarea);
      const height = computedStyle.height;
      const height1 = parseInt(height, 10);
      console.log("height",height)
      console.log("height",height1)


      const clientheight = window.innerHeight * 0.2;
      if (isNaN(height1) || height1 < clientheight) {
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
        if (this.newMessage.trim() === '') {
          textarea.style.height = '22px';
        }
        textarea.scrollTop = 0;
      }
    },
    sendMessage(){
      this.$emit("send1",this.textarea2)
      this.textarea2=""
    },
    handleInput() {
      // 计算输入框内容的行数
      this.currentRows = this.textarea2.split('\n').length;
      console.log("currentRows",this.currentRows)
    },
    NavigatTo(refName){
      console.log("this.$parent", this.$parent)
      // 使用 this.$refs 访问目标元素
      let distanceY = this.$parent.$refs[refName].offsetTop;
      console.log("distanceY", distanceY)
      console.log("window.scrollY", window.innerHeight)
      // 使用 scrollIntoView 方法滚动到该元素s
      window.scrollBy({
        top: distanceY-window.scrollY-window.innerHeight*0.07, // 向下滚动100像素
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<template>
<div class="NavigationContainer">
  <div v-if="visible" style="position: fixed;width: 100%;height: 100%;background-color: rgba(128, 128, 128, 0.5);top:0;left: 0;z-index: 9999999">
    <div class="gptassistent" style="position: fixed;;top:50%;left: 50%;transform: translateY(-50%) translateX(-50%);width: 95%;height: 70%;z-index: 9999;overflow-y: scroll">
      <GPTAssistants></GPTAssistants>

    </div>
    <img @click="visible=false" style="height:2vw;width:2vw;position:fixed;right: 3vw;top: 9vh" src="@/assets/error.png">
  </div>
  <div>
  <div  class="inputContent">
    <el-input
        type="textarea"
        :autosize="{ minRows: 1, maxRows: 4}"
        placeholder="Search for any questions"
        resize="none"

        style="font-size: 1.2vw;overflow-y: hidden;font-family: 'Times New Roman'"
        v-model="textarea2">
    </el-input>
    <div @click="sendMessage" style="cursor: pointer;position: absolute;display: flex;right: 17vw; bottom: 0;height: 2vw;padding: 0vh 1vw; transform: translateY(-100%);border-radius: 30px;background: linear-gradient(to right, #117DD3, rgba(245,92,82,0.8));">
      <img  style="height: 1vw;width: 2vw;margin: auto" src="@/assets/right_white.png">
    </div>

  </div>
  <div  class="Navigation">
    <div class="N1" style="position: relative" @click="visible=true">
      <div style="padding: 0 0 0 1vw">
        Explore AI Tools
      </div>
      <div style="height: 1.4vw;position: absolute;right: 1vw">
        <img style="width: 1.4vw;" src="@/assets/more_btn_icon.png_white.png">
      </div>
    </div>
    <div class="N1" id="N2" @click="toLinkedin">
      <div style="margin: auto 1vw auto 1vw;width: 1.8vw;height: 1.8vw">
        <img style="width: 1.8vw;height: 1.8vw" src="@/assets/linkin_blue.png">
      </div>
      <div >
        Follow Us,Free Use
      </div>
    </div>
  </div>
  <div  class="bottomContent">
    <div class="item"  @click="NavigatTo('consultMove')" >
      <div class="content1" >Smart Oilfield</div>
      <div class="content2">Full - scenario digital - intelligent oilfield solutions</div>
      <div class="content3" >
        <img src="@/assets/more_btn_icon.png_black.png">
      </div>
    </div>
    <div class="item" @click="NavigatTo('professional')">
      <div class="content1" >Customized AI</div>
      <div class="content2">Customization of AI applications in oil and gas industry scenarios</div>
      <div class="content3" >
        <img src="@/assets/more_btn_icon.png_black.png">
      </div>
    </div>
    <div class="item" @click="NavigatTo('software')">
      <div class="content1" >Software Products</div>
      <div class="content2">Full - scenario digital - intelligent oilfield products and software development</div>
      <div class="content3" >
        <img src="@/assets/more_btn_icon.png_black.png">
      </div>
    </div>
    <div class="item" @click="NavigatTo('mediaMove')">
      <div class="content1" >Intelligent Multimedia</div>
      <div class="content2">To boost your brand's spread in the digital wave</div>
      <div class="content3" >
        <img src="@/assets/more_btn_icon.png_black.png">
      </div>
    </div>
  </div>
  <transition appear name="expand">
    <div style="position: absolute;bottom: -6vh;height: 2vw;width: 2vw;left: 49%" >
      <div style="position: relative;height: 2vw;width: 2vw">
        <img src="@/assets/arrow.png" class="animated-element" style="width: 1.5vw;height: 1.5vh;position: absolute">
        <img src="@/assets/arrow.png" class="animated-element2" style="width: 1.5vw;height: 1.5vh;position: absolute">
        <img src="@/assets/arrow.png" class="animated-element3" style="width: 1.5vw;height: 1.5vh;position: absolute">
        <img src="@/assets/arrow.png" class="animated-element4" style="width: 1.5vw;height: 1.5vh;position: absolute">
      </div>
    </div>

  </transition>
</div>

</div>
</template>

<style scoped lang="scss">
.NavigationContainer{
  width: 100%;
  height: 66vh;
  position: relative;
  font-family: Sora;
  .Navigation{
    width: 100%;
    height: 5.5vh;
    //background-color: white;
    display: grid;
    padding: 0 29vw;
    gap: 5vw;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr);
    .N1 {
      height: 100%;
      display: flex;
      cursor: pointer;
      //color: black;
      //border:1px solid gray;
      background-color: #117cd1;
      //margin: 1vh 1vw;
      //padding: 3vh 1vw 7vh 3vw;
      font-size: 1.2vw;
      border-radius: 9px;
      color: white;
      text-align: center;
      //display: grid;
      align-items: center;
      //background-image: url("../../assets/homebanner1.jpg");
      background-size: cover;
    }
    #N2{
      //background-image: url("../../assets/homebanner2.jpg");
    }
    .title1{
      font-size: 2vw;
      font-weight: bolder;
      color: #6262f4;
      //font-family: initial;
      text-align: left;
    }
    .title2{
      color: #000000;
      font-size: 1vw;
      //font-weight: bolder;
      //font-family: "Times New Roman";
      text-align: left;

    }
  }
  .inputContent{
    font-size: 2vw;
    padding:1.5vw 16vw ;
    height: fit-content;
    border-radius: 9px;
    position: relative;
    ::v-deep .el-textarea__inner{
      overflow-y: hidden;
      border-radius: 30px;
      min-height: 4vw;
      font-family: "Times New Roman";
      padding: 0.6vw 5vw 0.6vw 2vw;
      //min-height: 20vh !important;
    }
  }
  .bottomContent{
    display: grid;
    width: 100%;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    //position: absolute;
    //bottom: 19vh  ;
    margin-top:10vh;
    padding:0 3vw;
    font-size: 1.2vw;
    font-weight: bolder;
    align-items: center;
    height: fit-content;
    .item{
      height: 100%;
      position: relative;
      padding: 4vh 2.5vw;
      border: 2px solid #dcdfe6;
      text-align: left;
      font-weight: normal;
      cursor: pointer;
      margin-left: 1vw;
      background-color: rgb(246, 246, 246);
      //padding: 1vh 1vw;
      border-radius: 28px;

      .content1{
        font-size: 1.2vw;

      }
      .content2{
        font-size: 1vw;
        color: gray;
        margin-top: 2vh;
      }
      .content3{
        position: absolute;right: 1.5vw;top: 1.5vw;
        img {
          width: 1.8vw;
          height: 1.8vw;
        }

      }
    }
  }
  .animated-element{
    //height: 10vh;
    animation: jiantou 3s linear infinite 0S;
  }
  .animated-element2{
    //height: 10vh;
    animation: jiantou 3s linear infinite 0.8s;
  }
  .animated-element3{
    //height: 10vh;
    animation: jiantou 3s linear infinite 2s;
  }
  .animated-element4{
    //height: 10vh;
    animation: jiantou 3s linear infinite 3s;
  }
  .expand-enter-active {
    animation: bounceIn 5s linear both;
  }
  .expand-height-enter-active,
  .expand-height-leave-active {
    transition: height 3s ease;
  }

  .expand-height-enter-from,
  .expand-height-leave-to {
    height: 58vh;
  }

  .expanded {
    height: 75vh;
    overflow: hidden;
  }
  .communicate {
    height: 100%;
    width: 64vw;
    padding: 0 1vw;
    margin: 1.5vh auto 0 auto;
    background: transparent;
    font-size: 1.1vw;
    overflow-y: auto;
    overflow-x: hidden;
    //border: 5px solid black;
  }
  .communicate::-webkit-scrollbar-track-piece {
    background-color: #f8f8f800;
  }
  .communicate::-webkit-scrollbar {
    width: 6px;
    transition: all 2s;
  }
  .communicate::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    border-radius: 100px;
  }
  .communicate::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
  }
  .communicate::-webkit-scrollbar-corner {
    background-color: rgba(255, 255, 255, 0);
  }
}
</style>

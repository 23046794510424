<script>
export default {
  data(){
    return{
      textarea2:''
    }
  },
  methods:{
    msSend1(){
      console.log("12312312321")
      this.$emit("msSend1",this.textarea2)
    }
  }

}
</script>

<template>
<div class="NaviContainer" >
  <div style="width: 80vw;height: fit-content;margin-left: 10vw;position: relative">
    <el-input style="margin-top: 4vh;border-radius: 15px" :autosize="{ minRows: 3, maxRows: 9}" type="textarea" v-model="textarea2" resize="none" placeholder="Search for any questions">

    </el-input>
    <img  @click="msSend1" style="width: 9vw;height: 9vw;position: absolute;bottom: 1.5vw;right: 1.5vw" src="@/assets/homeSend.png">
  </div>
  <div style=" height: 14vh;width: 80%;margin: auto;gap: 10vw;display: grid;grid-template-columns: minmax(0,1fr) minmax(0,1fr);">
    <a class="N"  href="/gptAssistantsMobile">
      <div style="margin: auto 0 auto 3vw;font-family: Sora">
        Explore AI Tool
      </div>
<!--      <div style="position: absolute;right: 4vw;top: 50%;transform: translateY(-50%)">-->
<!--        <img style="width: 4vw;height: 4vw" src="@/assets/more_btn_icon.png_white.png">-->
<!--      </div>-->

    </a>

    <a class="N"  href="https://linkedin.com/company/oil-gas-gpts">
      <div style="margin: auto 0 auto 2vw;">
        <img style="width: 7vw;height: 7vw" src="@/assets/linkin_blue.png">
      </div>
      <div style="font-family: Sora;margin: auto 0 auto 3vw;text-align: center;line-height: 4vw">
        Follow Us!<br>Free Use!
      </div>
    </a>

  </div>

  <div class="naviBox" style="display: flex;height: fit-content;width:80%;margin: 6vh auto 0 ">
    <!--              <div style="display: flex;height: 100%;width: 26vw;align-content: center;align-items: center">-->
    <!--                <img style="height: 9vw;width: 9vw" src="@/assets/homebanner3.png">-->
    <!--                <div style="font-weight: bolder" ><span style="color: #117cd2">Anvi</span><span style="color: #f0382a">sion</span><br>Services</div>-->
    <!--              </div>-->
    <div style="height: 100%;width: 100%;display: grid;gap: 3vw;grid-template-columns: minmax(0,1fr)">
      <div style="display: grid;gap: 2vw;grid-template-columns: minmax(0,1fr) minmax(0,1fr);height: fit-content">
        <div class="item" style="height: 100%" @click="NavigatTo('station')">
            Smart Oilfield
          <div class="content2">
            Full - scenario digital - intelligent oilfield solutions
          </div>
        </div>
        <div class="item" style="height: 100%" @click="NavigatTo('professional')">
          Customized AI
          <div class="content2">
            Customization of AI applications in oil and gas industry scenarios
          </div>
        </div>
      </div>
      <div style="display: grid;gap: 2vw;grid-template-columns: minmax(0,1fr) minmax(0,1fr)">
        <div class="item" style="height: 100%" @click="NavigatTo('software')">
          Software Products
          <div class="content2">
            Full - scenario digital - intelligent oilfield products and software development
          </div>
        </div>
        <div class="item" style="height: 100%" @click="NavigatTo('media')">
          Intelligent Multimedia
          <div class="content2">
            To boost your brand's spread in the digital wave
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
</template>

<style scoped lang="scss">
.NaviContainer{
  width: 100%;
  height:fit-content;

  .item{
    font-family: Sora;
    font-weight: bolder;
    background-color: white;
    margin: 0.5vw 0.5vw;
    font-size: 3vw;
    color: black;
    text-overflow: ellipsis;
    width: 100%;
    height: fit-content;
    border-radius: 9px;
    padding: 1vh 2vw ;
    //justify-content: center;
    text-align: left;
    //display: flex;
    align-items: center;
    .content2{
      line-height:1.4;
      margin-top: 1vh;
      font-size: 2vw;
      color: gray;
    }
  }
  .N{
    display: flex;
    position: relative;
    height: 5vh;
    //background-color: white;
    //margin: 0vh 10vw;
    margin-top: 4vh;
    padding: 0 2vw;
    border-radius: 9px;
    background-color: #117cd1;
    background-size: cover;
    text-align: left;
    color: white;
    font-weight: bolder;
    font-size: 3vw;
    .title1{
      font-size: 6vw;
      margin-top: 4vh;
      color: #6262f0;
    }
    .title2{
      font-weight: normal;
      font-size: 4vw;
      margin-top: 2vh;
      line-height: 6vw;
      color: black;
    }

  }
  #N2{
    background-image: url("../../assets/homebanner2.jpg");
  }
  ::v-deep .el-textarea__inner{
    padding: 3vw 10vw 3vw 4vw !important;
    border-radius: 15px !important;
  }
}

</style>

<template>
  <div >
    <div style="height: 100%" v-if="model === false" >
      <div class="layer1" @click="changevisibleother">
        <div class="leftLayer" @click="back"></div>
        <div class="rightLayer">
<!--          <div >-->

<!--            <i class="el-icon-search"-->
<!--               style="margin-right: 0.8vw;z-index: 999999999;font-size: 1vw;color:gray;font-weight: bold;height: 5vh;display: flex;align-items: center;cursor: pointer" @click="changevisible"></i>-->
<!--          </div>-->
          <div class="communicityGpt"  @click="back" style="font-family: Sora;color: black">Home</div>
<!--          <div class="communicity" @click="show" style="font-family: Sora">Join Now</div>-->

          <div v-if="userId" class="loginUser">
            <div style="display: flex">
              <el-dropdown class="userInfo">
                <div
                    class="el-dropdown-link"
                    style="display: flex; align-items: center"
                >
                  <el-avatar :size="30" :src="avator" class="avator"></el-avatar>
                  <div class="userName">
                    <span></span>
                    {{ userName }}
                  </div>
                </div>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item  style="display: flex; height:32px;text-align: left;font-size: 1vw;font-family: arial, sans-serif, 'Microsoft Yahei'"
                                     class="hoverItem"
                  >
                    <router-link style="display: flex" class="flex" to="/userInformation">
                      <div  @click="routeInfo">
                        <img
                            alt=""
                            class="icon not"
                            style="width: 20px; height: 20px; margin: 7px 5px auto 0"
                            src="@/assets/userIcon.png"
                        />
                      </div>
                      <span style="margin-top: 0;font-size: 14px">Personal center</span>
                      <span style="position: absolute;right:0px;margin-left: 10px;font-size: 14px"><i class="el-icon-arrow-right"></i></span>
                    </router-link>
                  </el-dropdown-item>

                  <el-dropdown-item
                      style="display: flex; text-align: left;font-size: 1vw;height: 32px"
                      @click.native="outLogin()"
                  >
                    <img loading="lazy"
                         alt=""
                         class="icon hover"
                         src="@/assets/outHoverIcon.png"
                         style="width: 20px; height: 20px; margin: 8px 5px auto 0"
                    />

                    <span style="margin-top: 0;font-size: 14px">Login out</span>
                    <span style="position: absolute;right:0px;margin-left: 10px;font-size: 14px"><i class="el-icon-arrow-right"></i></span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div v-else class="login" @click="login" style="font-family: Sora;color: black">Sign In</div>
          <div class="communicitynet" >
<!--            <div class="net"></div> &nbsp;&nbsp;-->
            <el-dropdown @command="handleCommand" style="font-size: 1vw;font-weight: bolder;font-family: Sora;color: black">
              <span class="el-dropdown-link" >
                EN<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="custom-dropdown-menu">
                <el-dropdown-item command="CN" style="font-weight: bolder;font-size: 1vw">中文</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

        </div>

      </div>
      <div style="position: absolute;right: 0;top: 0;width: 10vw;height: 100%;z-index: 100;display: flex" >
        <div style="position: absolute;top: 50%;transform: translateY(-50%);font-size: 0.8vw;display: flex;align-items: center;font-weight: bolder;font-family: Sora">
          Preview mode &nbsp;&nbsp;&nbsp;
          <el-switch
              v-model="moveSwitch"
              @change="start"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </div>
      </div>

      <el-popover
          placement="bottom"
          popper-class="down-popover"
          trigger="manual"
          width="1080"
          v-model="visible"
          style="z-index: 100;cursor: pointer"
      >
        <div>
          <div style="width: 100%;padding: 1vh 2vw">
            <el-input v-model="keyWords" style="border: none" placeholder="Search" @input="getSearch"></el-input>
          </div>

          <div class="searchBack" v-if="search.length>0">

            <li v-for="item in search" :key="item">
              <div class="searchContent" @click="routerSkip(item.link)">
                <div class="title1" v-html=" item.title"></div>
                <div class="title2" v-html="item.contend"></div>
              </div>
            </li>
          </div>
          <div v-else class="searchBackno">
            The information you are looking for about <span style="color: #eb7c31;margin: 0 0.5vw">“{{keyWords}}”</span>has not been retrieved yet, Try changing the keywords！
          </div>
        </div>


        <div slot="reference" class="search" style="margin-top: 0vh;background-color: unset;left: 39vw" @click="changevisible" >
<!--          <el-input v-model="keyWords" style="border: none" placeholder="Search" @input="getSearch"></el-input>-->

          <i class="el-icon-search"
             style="margin-right: 0.8vw;z-index: 999999999;font-size: 1vw;color:gray;font-weight: bold;height: 7vh;display: flex;align-items: center"></i>
        </div>

      </el-popover>

    </div>
    <div v-else>
      <div class="layerModel">
        <div v-if="showsearch" class="searchnew" style="z-index: 9999999999999999999999999;;">
          <div style="float: right;margin: 15px"><i class="el-icon-circle-close" style="font-size: 20px;font-weight:bold;color: #163eee"
                                                   @click="showsearch=false"></i></div>
          <div class="search">
            <el-input v-model="keyWords" style="border: none" placeholder="Please enter the GPT or content keyword" @input="getSearch"></el-input>

            <i class="el-icon-search"
               style="margin-right: 15px;z-index: 99;font-size: 16px;color:gray;font-weight: bold;height: 45px;display: flex;align-items: center;justify-content: center"></i>
          </div>

          <div v-if="search.length>0" class="searchBack">
            <li v-for="item in search" :key="item">
              <div class="searchContent" @click="routerSkip(item.link)">
                <div class="title1" v-html="item.title"></div>
                <div class="title2" v-html="item.contend"></div>
              </div>

            </li>

          </div>
          <div v-else class="searchBackno">
            The information you are looking for about <span style="color: #eb7c31;margin: 0 0.5vw">“{{ keyWords }}”</span>has not been retrieved yet, Try changing the keywords！
          </div>
        </div>
        <div class="leftLayer" @click="back"></div>

        <div class="rightLayer">
          <div v-if="userId" class="loginUser">
            <div class="login" style="margin:0 -10px 0 0" @click="showsearch=true"><i style="color: black" class="el-icon-search"/></div>
            <div class="communicitynet" >
<!--              <div class="net"></div> &nbsp;&nbsp;-->
              <el-dropdown @command="handleCommand" style="font-size: 1vw;font-weight: bolder">
              <span class="el-dropdown-link" >
                EN<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="CN" style="font-weight: bolder;font-size: 1vw">中文</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <el-dropdown class="userInfo">
              <div
                  class="el-dropdown-link"
                  style="display: flex; align-items: center"
              >
                <el-avatar :size="30" :src="avator" class="avator"></el-avatar>
                <div class="userName">
                  <span></span>
                  {{ userName }}
                </div>
              </div>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item  style="display: flex; height:32px;text-align: left;font-size: 14px;font-family: arial, sans-serif, 'Microsoft Yahei'"
                                   class="hoverItem">
                  <router-link style="display: flex" class="flex" to="/infoMobile">
                    <div>
                      <img
                          alt=""
                          class="icon not"
                          style="width: 20px; height: 20px; margin: 7px 5px auto 0"
                          src="@/assets/userIcon.png"
                      />
                    </div>
                    <span style="margin-top: 0">Personal Center</span>
                    <span style="position: absolute;right:0px;margin-left: 10px;font-size: 14px"><i class="el-icon-arrow-right"></i></span>

                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item
                    style="display: flex; text-align: left;font-size: 14px;height: 32px"
                    @click.native="outLogin()"
                >
                  <img
                      alt=""
                      class="icon hover"
                      src="@/assets/outHoverIcon.png"
                      style="width: 20px; height: 20px; margin: auto 5px auto 0"
                  />
                  Login out
                  <span style="position: absolute;right:0px;margin-left: 10px;font-size: 14px"><i class="el-icon-arrow-right"></i></span>

                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <div
                  class="el-dropdown-link"
                  style="display: flex; align-items: center"
              >
                <img :src="three" class="communicitynet"
                     style="height: 23px;width: 20px;border-radius:0;display: flex;justify-content: center;align-items: center">
              </div>

              <el-dropdown-menu slot="dropdown">

                <el-dropdown-item
                    class="hoverItem"
                    style="display: flex; height:32px;text-align: left;font-size: 14px;font-family: arial, sans-serif, 'Microsoft Yahei'">
                  <div @click="show">
                    <span style="margin-top: 0">Join Now</span>
                  </div>
                </el-dropdown-item>

                <el-dropdown-item
                    style="display: flex; text-align: left;font-size: 14px;height: 32px"
                >
                  <div @click="gpts">GPT Community</div>


                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </div>
          <div v-else style="display: flex">
<!--            移动端二三级页面header-->
            <div class="login" style="margin:0 -10px 0 0" @click="showsearch=true"><i style="color: black"  class="el-icon-search"/></div>
            <div class="communicitynet" >
              <!--              <div class="net"></div> &nbsp;&nbsp;-->
              <el-dropdown @command="handleCommand" style="font-size: 1vw;font-weight: bolder">
              <span class="el-dropdown-link" style="font-size: 14px;color: black;margin:0px 0 0 17px">
                EN<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="CN" style="font-size: 14px;color: black;">中文</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div style="color: black"  class="login" @click="login"> Sign In</div>
            <el-dropdown>
              <div
                  class="el-dropdown-link"
                  style="display: flex; align-items: center"
              >
                <img :src="three" class="communicitynet"
                     style="height: 23px;width: 20px;border-radius:0;display: flex;justify-content: center;align-items: center">
              </div>

              <el-dropdown-menu slot="dropdown">

                <el-dropdown-item
                    class="hoverItem"
                    style="display: flex; height:32px;text-align: left;font-size: 14px;font-family: arial, sans-serif, 'Microsoft Yahei'">
                  <div @click="show">
                    <span style="margin-top: 0">Join Now</span>
                  </div>
                </el-dropdown-item>

                <el-dropdown-item
                    style="display: flex; text-align: left;font-size: 14px;height: 32px"
                >
                  <div @click="gpts">GPT Community</div>


                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

        </div>
      </div>
      <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
    </div>
    <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
  </div>
</template>

<script>
import {
  logOut,
  userDetail,
  getAuthorize,
  // getLoginStatus,
  // deleteUserAgent,
} from "@/api/user";
import Fingerprint2 from "fingerprintjs2";
import PartnerTip from "@/components/partnerTip.vue";

export default {

  name: "headtip",
  props: {
    changeVisable:{
      type: Number,
      required: true
    },
  },

  components: {PartnerTip},
  data() {
    return {
      moveSwitch:false,
      three: require("@/assets/three.png"),
      visible: 0,
      model: false,
      userName: "",
      showsearch: false,
      keyWords: "",
      avator: require("@/assets/user.jpg"),
      userId: localStorage.getItem("userId"),
      dialogconsult: false,
      userInfo:'',
      keyTitle:"",
      baseURL: this.$utils.baseURLEN,
      baseURLEN: this.$utils.baseURL,
      search: [
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
      ],
      value: 'EN',
      options: [
      //     {
      //   value: '选项1',
      //   label: 'EN'
      // },
        {
        value: '选项2',
        label: '中文'
      },],

    };
  },
  created() {
    this.getSearch()
    console.log("userId", this.userId);
  },
  watch: {
    "$store.state.userId"(newValue, oilValue) {
      this.userId = newValue;
      if (newValue && newValue != oilValue) {
        this.getUser();
        this.ifComplete()
      }
    },
    "changeVisable"(newValue) {
      this.changeVisable = newValue;
      if (    this.visible===1){
        this.visible=0
      }
      console.log("子组件", this.changeVisable);
    },
  },
  mounted() {
    const screenWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    const screenHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

    // Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if (screenWidth <= 700) {
      this.model = true;
    } else {
      this.model = false;
    }
    let murmur;
    let that = this;
    var options = {
      excludes: {
        enumerateDevices: true,
        platform: true,
        addBehavior: true,
        cpuClass: true,
        deviceMemory: true,
        hardwareConcurrency: true,
        colorDepth: true,
        screenResolution: true,
        availableScreenResolution: true,
        indexedDb: true,
        webgl: true,
        plugins: true,
        canvas: true,
        pixelRatio: true,
      },
    };
    console.log(options, "options");
    Fingerprint2.get(options, (components) => {
      const values = components.map(function (component, index) {
        if (index === 0) {
          return component.value.replace(/\bNetType\/\w+\b/, "");
        }
        return component.value;
      });
      murmur = Fingerprint2.x64hash128(values.join(""), 31);
      that.murmur = murmur;
      localStorage.setItem("murmur", murmur);
      that.isLogin();
    });
    this.userId = localStorage.getItem("userId");
    if (this.userId) {
      this.getUser();
      this.ifComplete()
    }
  },
  methods: {

    start(){
      // console.log("hahahahahah------------")
      this.$emit("startAutoScroll",true)
    },
    handleCommand(command){
      if (command=='CN'){
        // window.open(this.baseURL, "_blank");
        if (this.$route.name=='Home'){
          window.open(this.baseURL, "_blank");
        }
        else {
          window.open(this.baseURL+"/"+ this.$route.name, "_blank");
        }

      }
    },
     topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      const element = this.$refs.totalModel;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    getSearch() {
      console.log(this.search.length)
      this.$axios
          .get("https://cn.oilgasgpts.com/search/getThirdByName_IntroduceEn?keyword=" + this.keyWords, {
            // .get("http://192.168.200.50:16542/search/getThirdByName_Introduce?keyword=" + this.keyWords, {
            headers: {
              Authorization: "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjUyNjZiN2QxLTNhOWUtNDBiOC05MGJlLTNjMTU1OGM3YmI3YiJ9.OsIDjD4UL8AZHu5j5KO-26IQwJu6uVvu59KNM18vuLmjzXMJFl-lX2vyyw_4L1AXC6wot6lpxhv_JZUVe1LokQ",
            },
          })
          .then((res) => {
            console.log("search", res.data)
            this.search = []
            var searchdata = res.data.data.thirdPageList

            searchdata.forEach((item, index) => {
              if(res.data.data.keywordList[0]!==""){
                item.leighttitle = this.highlightText(item.title,  res.data.data.keywordList);
                item.leightcontend = this.highlightText(item.introduce,  res.data.data.keywordList);
                this.$set(this.search, index, {
                  link: item.url,
                  title: item.leighttitle,
                  contend: item.leightcontend,
                });
              }
              else{
                this.$set(this.search, index, {
                  link: item.url,
                  title: item.title,
                  contend: item.introduce,
                });
              }
            })
            console.log("成都", this.search.length)
          })
          .catch((err) => {
            console.log(err);
          });
    },
    highlightText(text, keywords) {
      const regex = this.createHighlightRegex(keywords);
      return text.replace(regex, (match) => `<span style="color: #eb7c31">${match}</span>`);
    },
    createHighlightRegex(keywords) {
      const escapedKeywords = keywords.map(keyword => keyword.replace(/[/\\^$*+?.()|[\]{}]/g, '\\$&'));
      const regexPattern = escapedKeywords.join('|');
      return new RegExp(regexPattern, 'gi');
    },
    changevisible(){
      if (this.visible===0){
        this.visible=1
      }
      else{
        this.visible=1
      }
    },
    changevisibleother() {
      if (this.visible === 1) {
        this.visible = 0
      }
    },
    routerSkip(link) {
      window.open(link, "_blank");
    },
    isLogin() {
      let murmur = localStorage.getItem("murmur");
      let idass = this.$store.state.idass;
      let data;
      console.log(idass, "idass");
      if (idass && idass != "undefined") {
        data = {
          userAgent: murmur,
          idass: idass,
        };
      } else {
        data = {
          userAgent: murmur,
        };
      }
      // getLoginStatus(JSON.stringify(data))
      this.$axios
          .post("https://www.oilgasinfoai.com/auth/oauth/getLoginStatus", data)
          .then((res) => {
            console.log(res, "res11111");
            let options = this.$route.query;
            if (res.data.data.isLogin == true && this.$store.state.access_token) {
              this.getUser();
              this.ifComplete()
            } else if (
                res.data.data.isLogin == true &&
                !this.$store.state.access_token &&
                !options.code
            )
            {
              this.login();
            }
            else if (res.data.data.isLogin == false && res.data.data.type == 2) {
              console.log("isLogin Flase type 2");
              //this.outLogin();
              localStorage.removeItem("userInfo");
              localStorage.removeItem("access_token");
              localStorage.removeItem("userId");
              localStorage.removeItem("typeInfo");
              localStorage.removeItem("access_tokenout");
              localStorage.removeItem("Zyaccess_token");
              localStorage.removeItem("idass");
              this.$store.state.access_token = "";
              this.$store.state.idass = "";
              this.$store.state.user = {};
              this.userId = "";
              this.userName = "";
            } else if (res.data.data.isLogin == false && res.data.data.type == 1 ) {
              console.log("isLogin Flase type 1");
              localStorage.removeItem("userInfo");
              localStorage.removeItem("access_token");
              localStorage.removeItem("userId");
              localStorage.removeItem("typeInfo");
              localStorage.removeItem("access_tokenout");
              localStorage.removeItem("Zyaccess_token");
              localStorage.removeItem("idass");
              this.userId = "";
              this.userName = "";
              this.$store.state.access_token = "";
              this.$store.state.idass = "";
              this.$store.state.user = {};
              this.login();
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    ifComplete(){
      this.$axios
          .get("/info/gpts_user/judgment_info/" + this.userId)
          .then((res) => {
            if(res.data.msg=="信息已完善"){
              localStorage.setItem("infoComplete",1)
            }
            else{
              localStorage.setItem("infoComplete",0)
            }

            console.log("--------ren",res.data)

          })
          .catch((err) => {
            console.log(err);
          });

    },
    getUser() {
      userDetail(this.userId).then((res) => {
        this.userInfo = res.data;
        localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        localStorage.setItem("userId", res.data.gptsUserId);
        this.$store.state.user = res.data;
        this.$store.state.userId = res.data.gptsUserId;
        this.userId = res.data.gptsUserId;
        this.userName = this.userInfo.gptsUserName;
        this.ifComplete()

      });
    },
    routeInfo(){
      this.$route.push("/userInformation")
    },
    outLogin() {
      // localStorage.removeItem("userId");
      // window.location.reload();
      logOut()
          .then((res) => {
            localStorage.removeItem("userInfo");
            localStorage.removeItem("access_token");
            localStorage.removeItem("userId");
            localStorage.removeItem("typeInfo");
            localStorage.removeItem("access_tokenout");
            localStorage.removeItem("Zyaccess_token");
            localStorage.removeItem("idass");
            this.$store.state.access_token = "";
            this.$store.state.idass = "";
            this.$store.state.user = {};
            // deleteUserAgent(localStorage.getItem("murmur"))
            let data = {
              userAgent: localStorage.getItem("murmur"),
            };
            this.$axios
                .post(
                    "https://www.oilgasinfoai.com/auth/oauth/deleteUserAgent",
                    data
                )
                .then(() => {
                  localStorage.removeItem("murmur");
                  window.location.href = res.data;
                  this.$router.push("/")
                });
          })
          .catch((error) => {
            console.error("退出登录失败:", error);
            this.$message.error("退出登录失败，请重试"); // 提示用户退出登录失败
            // 可以在此处执行其他的错误处理逻辑，例如重新加载页面或者显示其他提示信息
          });
    },
    login() {
      // this.$router.push("/login")
      // let href = "https://www.oilgasgpts.com/";
      let href = window.location.href.split("&code")[0];
      getAuthorize(href).then((res) => {
        window.location.href = res.data.url;
      });
    },
    back() {
      window.open(this.baseURLEN, "_blank");
    },
    Chinese() {
      const routeName = this.$route.name;

      console.log("-----------------")
      console.log(this.value)
      if(this.value==="选项2")
      {
        if (routeName === "Home") {
          window.open(this.baseURL, "_blank");
        } else if (routeName === "feedbackEn") {
          window.open(this.baseURL+"/feedback", "_blank");
        }   else if(routeName==="Privacy"){
          window.open(this.baseURL, "_blank");
        }else {
          window.open(this.baseURL+"/"+ routeName, "_blank");
        }

      }
      else{
        if(routeName==="Home"){
          window.open(this.baseURLEN, "_blank");
        }

        else{
          window.open(this.baseURLEN+routeName, "_blank");
        }

      }
    },
    feedback(){
      this.$router.push("/feedbackEn")
      // window.open("https://mall.antonoil.com/gpts_feedback", "_blank");

    },
    gpts() {
      window.open("https://www.oilgascommunity.com/sortList?id=39", "_blank");
    },
    show() {
      this.$emit("showDialog");
    },
    hide() {
      this.dialogconsult = false;
    },
  },
};
</script>

<style>
.custom-dropdown-menu {

  padding: 0 0 !important;
}


.down-popover {
  height: 50vh !important;
  overflow: auto;
  padding: 0 !important;
  left: 10vw !important;
  background: linear-gradient(180deg,#f5f4f6,#e6ebf7) !important;
  border: none !important;
}

.down-popover::-webkit-scrollbar-track-piece {
  background: linear-gradient(180deg,#f5f4f6,#e6ebf7);
}

.down-popover::-webkit-scrollbar {
  width: 6px;
  transition: all 2s;
}

.down-popover::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg,#f5f4f6,#e6ebf7);
  border-radius: 100px;
}

.down-popover::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg,#f5f4f6,#e6ebf7);
}

.down-popover::-webkit-scrollbar-corner {
  background-color: rgba(255, 255, 255, 0);
}

.el-popper[x-placement^="bottom"] .popper__arrow::after {

  margin-left: -50px !important;

  border-bottom-width: 0;

  border-top-color: rgb(250, 250, 250) !important;

}

.el-popper[x-placement^=bottom] .popper__arrow {
  left: 10vw !important;
  border-bottom-color: rgb(250, 250, 250) !important;
}
</style>
<style lang="scss" scoped type="text/scss">
.el-dropdown-menu__item, .el-menu-item{
  padding: 0 10px !important;
}
.el-dropdown-menu{
  padding: 0 !important;
}
.avator {
  border: 2px solid #fff;
}
.searchBack {
  //background-color: rgba(102, 102, 102, 0.2);
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  column-gap: 1.5vw;
  row-gap: 2vh;
  padding: 3vh 2vw;
  list-style-type: none;
}
.searchBackno{
  display: flex;
  padding: 3vh 2vw;
  font-weight: bold;
  //justify-content: center;
  //align-items: center;
}
.searchContent {
  cursor: pointer;
  background-color: white;
  border: white 1px solid;
  box-shadow: 5px 0 14px 0px #e5ebfa;
  padding: 2vh 1.5vw;
  height: 100%;
  border-radius: 9px;
  //color: white;

  .title1 {
    font-size: 0.9vw;
    font-weight: bold;
    line-height: 3vh;
    text-align: left;
    white-space: pre-wrap;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* 限制在一个块元素显示的文本的行数 */
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title2 {
    font-size: 0.8vw;
    line-height: 2.8vh;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
    overflow: hidden;
    text-overflow: ellipsis;
    height:5.6vh ;
  }
}
.userName {
  color: black;
  font-size: 0.9vw;
  line-height: 1.3;
  text-align: left;
  margin-left: 10px;
  max-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.searchContent:hover {
  border: #9b85f4 1px solid;


}
.search {
  position: absolute;
  top:0.5vh;
  display: flex;
  left:9vw;
  border-radius: 9px;
  width: fit-content;
  background-color: white;
  margin-left: 2vw;

  ::v-deep .el-input {
    border-radius: 9px;
    width: 24vw;
    font-size: 0.8vw;
  }

  ::v-deep .el-input__inner {
    border-radius: 9px;
    height: 5vh;
  }
}
.layer1 {
  width: 99%;
  padding: 0vh 1vw 0 0;
  left:3vw;
  height: 100%;
  z-index: 99;
  display: flex;
  //justify-content: space-between;
  position: absolute;
  //padding: 0 1.61756210283073vw;

  .leftLayer {
    margin: auto 0;
    cursor: pointer;
    width: 8.2790294627383vw;
    height: 5.56242274412855vh;
    background-image: url("../assets/GPTLOGO.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    //border: #2c3e50 1px solid;
  }

  .rightLayer {
    width: fit-content;
    display: flex;
     position: absolute;
    left: 50%;
    //margin: auto 0;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    justify-content: center;
    align-items: center; /* Vertically center the content */
    .communicitynet {
      //z-index: 8;
      cursor: pointer;
      font-size: 1vw;
      font-family: 'Times New Roman';
      font-weight: bold;
      color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      padding: 2.46242274412855vh 1.5vw;
      height: 4.56242274412855vh;
      //border: #2f6eff 1px solid;
      //background: rgba(255, 255, 255,0.9)
      overflow: hidden; /* Add overflow property */
      position: relative;
      .net{
        background-image: url("../assets/earth2.png");
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        width: 20px;
        height: 20px;
        right:4.5vw;
        position: absolute;
      }
    }
    .communicitynet:hover {
      color: white;
      //font-weight: bold;
      ////background: rgba(255, 255, 255, 0.5);
      //border-bottom: white 3px solid;
      //background: rgba(255, 255, 255, 0.5);
    }
    ::v-deep .el-select .el-input__inner {
      border-radius: 9px;
      background-color: transparent;
      height: 5vh !important;
      width: 6vw;
      font-size: 1vw;
      //color: white;
      font-weight: bold;
      padding: 0 2vw;
      text-align: right;
    }
    ::v-deep .el-select .el-input .el-select__caret{
      color: black;
    }
    .communicityFeedback {
      //z-index: 8;
      cursor: pointer;
      font-size: 1vw;
      font-family: 'Times New Roman';
      font-weight: bold;
      color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      //border-radius: 9.1px;
      padding: 2.46242274412855vh 0.5vw;
      height: 4.56242274412855vh;
      //border: #2f6eff 1px solid;
      border-radius: 9.1px;
      //background: rgba(255, 255, 255,0.9);
      margin-right: 1.00065337954939vw;
      overflow: hidden; /* Add overflow property */
      //display: flex;
      .net{
        background-image: url("../assets/feedback.png");
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        width: 20px;
        height: 20px;
      }
    }
    .communicityFeedback:hover {
      color: white;
      font-weight: bold;
      //background: rgba(255, 255, 255, 0.5);
      border-bottom: white 3px solid;
    }
    .communicity {
      //z-index: 8;
      cursor: pointer;
      margin-left: 1vw;
      font-size: 1vw;
      font-family: 'Times New Roman';
      font-weight: bold;
      //color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      //border-radius: 9.1px;
      padding: 2.46242274412855vh 0.5vw;
      height: 4.56242274412855vh;
      //border: #2f6eff 1px solid;
      margin-right: 1.00065337954939vw;
      overflow: hidden; /* Add overflow property */
    }

    .communicity:hover {
      color: #1b257e;
      font-weight: bold;
      //background: rgba(255, 255, 255, 0.5);
      border-bottom: #1b257e 3px solid;
    }

    .loginUser {
      //z-index: 8;
      //border: #2F6EFF 1px solid;
      border-radius: 9.1px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 1vw;
      font-family: 'Times New Roman';
      color: white;
      line-height: 3.33745364647713vh;
      text-align: center;
      width: fit-content;
      height: 4.56242274412855vh;
      background: transparent;

      .flex {
        display: flex;
        align-items: center;
        height: 32px;
        margin-top: 12px;

        img.icon {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }

        .icon.hover {
          display: none;
        }
      }
    }

    .communicityGpt{
      //z-index: 8;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 1vw;
      font-family: 'Times New Roman';
      font-weight: bold;
      //color: white;
      line-height: 3.33745364647713vh;
      text-align: center;
      //border-radius: 9.1px;
      width: fit-content;
      padding: 2.46242274412855vh 0.5vw;
      height: 4.56242274412855vh;
      //background: #2f6eff;
      //border: #2f6eff 1px solid;
    }
    //.communicityGpt:hover {
    //  color: #1b1e76;
    //  font-weight: bold;
    //  //background: rgba(255, 255, 255, 0.5);
    //  border-bottom: #1b1e76 3px solid;
    //}
    .login {
      //z-index: 8;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 1vw;
      font-family: 'Times New Roman';
      font-weight: bold;
      //color:white;
      line-height: 3.33745364647713vh;
      text-align: center;
      //border-radius: 9.1px;
      width: fit-content;
      padding: 2.46242274412855vh 0.5vw ;
      height: 4.56242274412855vh;
      //background: #2f6eff;
      //border: #2f6eff 1px solid;
    }

    //.login:hover {
    //  color: #1b1e76;
    //  font-weight: bold;
    //  //background: rgba(255, 255, 255, 0.5);
    //  border-bottom: #1b1e76 3px solid;
    //}
  }
}

.layerModel {
  display: flex;
  justify-content: space-between;
  //background-color: #102078;
  //padding: 15px 5px 10px 5px;
  .searchnew {
    background-color: #d7d7d7;
    position: fixed;
    width: 100vw;
    top:0;
    left:0;
    height: 100vh;
    z-index: 99;

    .searchBack {
     margin-top: 105px;
      //background-color: rgba(102, 102, 102, 0.2);
      display: block;
      //grid-template-columns: minmax(0, 1fr);
      position: fixed;
      height: 90vh;
      overflow-y: auto;
      padding: 0 15px;
      row-gap: 15px;
      list-style-type: none;
    }

    .searchBackno {
      display: block;
      margin-top: 105px;
      padding: 15px 25px;
      font-weight: bold;
      text-align: center;
    }

    .searchContent {
      cursor: pointer;
      //background: #e5ebfa;
      margin-bottom: 15px;
      border: white 1px solid;
      background-color: #f2f2f2;
      //box-shadow: 5px 0 14px 0px #e5ebfa;
      padding: 10px;
      height: 100%;
      border-radius: 9px;
      //color: white;

      .title1 {
        font-size: 14px;
        font-weight: bold;
        line-height: 22px;
      }

      .title2 {
        font-size: 12px;
        margin-top: 5px;
        line-height: 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
        overflow: hidden;
        text-overflow: ellipsis;
        height: 40px;
      }
    }

    .search {
      margin: 35px 5vw 25px -5vw;
      display: flex;
      border-radius: 9px;
      width: 90vw;
      background-color: white;

      ::v-deep .el-input {
        border-radius: 9px;
        width: 80vw;

        font-size: 14px;
      }

      ::v-deep .el-input__inner {
        border-radius: 9px;
        height: 45px !important;
        border: white 1px solid !important;
        background-color: transparent !important;
      }

    }
  }
  .userName {
    color: black;
    font-size: 14px;
    text-align: left;
    margin-left: 10px;
    max-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .leftLayer {
    cursor: pointer;
    width: 80px;
    height: 20px;
    background-image: url("../assets/GPTLOGO.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    //border: #2c3e50 1px solid;
  }

  .rightLayer {
    margin-top: -0.89443757725587vh;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    .communicity {
      z-index: 8;
      cursor: pointer;
      font-size: 14px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      border-radius: 9.1px;
      padding: 15px 0;
      height:45px;
      overflow: hidden; /* Add overflow property */
    }
    .communicitynet {
      z-index: 8;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 14px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: white;
      line-height: 45px;
      text-align: center;
      border-radius: 9.1px;
      width: fit-content;
      padding: 5px;
      height: 35px;

      ::v-deep .el-input {
        border-radius: 9px;
        width: 75px;
        font-size: 14px;
        color: black;
      }
      ::v-deep .el-select .el-input .el-select__caret{
        color: black;
      }
      ::v-deep .el-input__inner {
        border-radius: 9px;
        color: black;
        height: 5vh !important;
        border: none !important;
        background-color: transparent !important;
      }
      .net{
        background-image: url("../assets/netModel.png");
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        width: 14px;
        height: 14px;
      }
    }


    .loginUser {
      z-index: 8;
      //border: #2F6EFF 1px solid;
      border-radius: 9.1px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 14px;
      font-family: 'Times New Roman';
      color:white;
      line-height: 3.33745364647713vh;
      text-align: center;
      width: fit-content;
      height: 45px;
      background: transparent;

      .flex {
        display: flex;
        align-items: center;
        height: 32px;
        margin-top: 12px;

        img.icon {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }

        .icon.hover {
          display: none;
        }
      }
    }

    .login {
      z-index: 8;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 14px;
      font-family: 'Times New Roman';
      font-weight: bold;
      color: #ffffff;
      //line-height: 15px;
      text-align: center;
      border-radius: 9.1px;
      width: fit-content;
      padding: 0 5px 2px 5px;
      //height: 35px;

    }


  }
}
.layer2 {
  .title1 {
    margin:0 auto;
    width: fit-content;
    height:23px;
    font-size: 23px;
    font-family: 'Times New Roman';
    font-weight: 500;
    color: #111111;
    line-height: 8.23733003708282vh;
  }
  .title2 {
    margin:35px auto 0 auto;
    width: fit-content;
    height:17px;
    font-size: 14px;
    font-family: 'Times New Roman';
    font-weight: 400;
    color: #111111;
    line-height:24px;
  }
  .title3 {
    display: flex;
    text-align: center;
    margin: 20px auto 0 auto;
    width: fit-content;
    height: 35px;
    font-size: 8px;
    font-family: 'Times New Roman';
    font-weight: bold;
    color: #2f6eff;
    line-height:22px;
  }
}
.layer3 {
  position: absolute;
  display: flex;
  bottom:15px;
  opacity: 1;
  width: 90%;
  justify-content: center;
  .communicity {
    //z-index: 8;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Times New Roman';
    font-weight: 400;
    color: #2f6eff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    border-radius: 9.1px;
    width: fit-content;
    margin:0 15px;
    padding: 10px 4.6875vw;
    height: 35px;
    border: #2f6eff 1px solid;
    overflow: hidden; /* Add overflow property */
  }
  .communicityGpt {
    //z-index: 8;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Times New Roman';
    font-weight: 400;
    color:white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    border-radius: 9.1px;
    width: fit-content;
    margin:0 15px;
    padding: 10px 4.6875vw;
    height: 35px;
    background-color:#2f6eff ;
    border: #2f6eff 1px solid;
    overflow: hidden; /* Add overflow property */
  }

}
</style>
